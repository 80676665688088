
import Vue from "vue";

export default Vue.extend({
  name: "DateRangePicker",

  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      required: false,
      default: "",
      type: String
    },
    min: {
      required: false,
      type: String,
      default: new Date("1970-01-01").toISOString()
    },
    max: {
      required: false,
      type: String,
      default: new Date("2100-01-01").toISOString()
    },
    hideDetails: {
      required: false,
      default: false,
      type: Boolean
    },
    clearable: {
      required: false,
      default: false,
      type: Boolean
    },
    mandatory: {
      required: false,
      default: false,
      type: Boolean
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      required: false,
      type: Boolean,
      default: false
    },
    dense: {
      required: false,
      type: Boolean,
      default: false
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false
    },
    format: {
      required: false,
      type: String,
      default: "DD.MM.YYYY"
    }
  },

  data: () => ({
    dates: [] as Array<any>,
    parsedDate: "" as string,
    menu: false as boolean,
    formattedDates: [] as Array<string>
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.dates = this.value || [];

        if (!this.dates.length) {
          return;
        }

        this.parseDate();
      }
    }
  },

  computed: {
    formattedDate(): string {
      const [from, to]: Array<number | string | Date> = [...this.dates].sort();

      const dateFrom = new Date(from).toLocaleDateString();
      const dateTo = new Date(to).toLocaleDateString();

      let formattedString = `${dateFrom} - ${dateTo}`;
      if (!to) {
        formattedString = `${dateFrom}`;
      }
      return formattedString;
    }
  },

  methods: {
    changeData(): void {
      let dates = this.dates;

      if (this.dates.length === 1) {
        const [date] = dates;
        dates = [date, date];
      }

      this.parseDate();
      this.$emit("input", dates.sort());
      this.menu = false;
    },
    close(): void {
      if (this.mandatory) {
        this.dates = [new Date().toISOString(), new Date().toISOString()];
      } else {
        this.dates = [];
      }
      this.changeData();
      this.menu = false;
    },
    setToday() {
      this.dates = this.getFormatted([this.$moment(), this.$moment()]);
    },
    setLastDays(days: number): void {
      this.dates = this.getFormatted([
        this.$moment().subtract(1, "days"),
        this.$moment().subtract(days, "days")
      ]);
    },
    setLast(value: any) {
      const values: any = `${value}s`;
      this.dates = this.getFormatted([
        this.$moment()
          .subtract(1, values)
          .startOf(value),
        this.$moment()
          .subtract(1, values)
          .endOf(value)
      ]);
    },
    getFormatted(arr: Array<any>): Array<string> {
      const [from, to] = arr;
      return [
        this.$moment(from).format("YYYY-MM-DD"),
        this.$moment(to).format("YYYY-MM-DD")
      ].sort();
    },
    parseDate() {
      const [from, to] = [...this.dates].sort();

      const dateFrom = this.$moment(from).format(this.format);
      const dateTo = this.$moment(to).format(this.format);
      let formattedDate = `${dateFrom} - ${dateTo}`;
      if (!to || dateFrom === dateTo) {
        formattedDate = dateFrom;
      }

      this.parsedDate = formattedDate;
    }
  }
});
